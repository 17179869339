import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Form,
  Select,
  Col,
  Input,
  Divider,
  Layout,
  DatePicker,
} from "antd";

import { useDispatch } from "react-redux";

import { SendFormData } from "../../../features/sendformDataSlice";

import { formDataActions } from "../../../features/sendformDataSlice";


import "../../userDetails/styles.css";
import { current } from "@reduxjs/toolkit";

function ClaimsReconsideration(props) {
  const {
    userDetails,
    setUserDetailsHandler,
    getAppealType,
    getFormTemplate,
    FormFieldValidationHandler,
    setFormFieldsValueHandler,
    currentDate,
    clearFieldValueHandler
  } = props;
  const { Header, Content, Sider } = Layout;
  const dateFormat = "MM/DD/YYYY";
  const dispatch = useDispatch();


  const [customCPTcodeErrMsg, setCustomCPTcodeErrMsg] = useState('');
  const [isValidCPTcodes, setIsValidCPTcodes] = useState(true);
  const [CPTcodes, setCPTcodes] = useState('');

  const { getFieldDecorator } = props.form;

  const children = [];
  // for (let i = 10; i < 36; i++) {
  // //   children.push(<Select.Option key={i.toString(36) + i}>{i.toString(36) + i}</Select.Option>);
  // }

  useEffect(() => {
    setUserDetailsHandler({
      patientName: "",
      memberID: "",
      DOS: "",
      ICN_Number: "",
      CPT_codes: "",
    });
  }, []);

  useEffect(() => {
    FormFieldValidationHandler.current = validateField
  }, [])

  useEffect(() => {
    clearFieldValueHandler.current = clearField
  }, [])

  const clearField = () => {
    setUserDetailsHandler({
      patientName: "",
      memberID: "",
      DOS: "",
      ICN_Number: "",
      CPT_codes: "",
    });
    props.form.resetFields();
  }


  function validateField() {

      props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (values.CPT_codes) {
          let formData = {
            formID: getFormTemplate.formID,
            patientName: values.patientName ? values.patientName : null,
            memberID: values.memberID ? values.memberID : null,
            DOB: values.DOB ? values.DOB : null,
            dateOfSubmission: currentDate ? currentDate : null,
            claimNumber: values.claimNumber ? values.claimNumber : null,
            dateOfService: values.DOS ? values.DOS : null,
            ICNnumber: values.ICN_Number ? values.ICN_Number : null,
            CPTcodes: values.CPT_codes ? values.CPT_codes.toString().replaceAll(',', ', ') : null,
          }
          dispatch(formDataActions.pdfDownload_pending())
          dispatch(SendFormData(formData))
        }
        else{
          setCustomCPTcodeErrMsg('Enter the CPT Codes!');
          setIsValidCPTcodes(false)
        }
      }
     
      })
    // }
  }


  return (
    <Col span={12} style={{ borderRight: "1px solid #cfcfcf" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Col span={22}>
          <div>
            <Form.Item className="input"
              label="Patient Name"
            >
              {getFieldDecorator("patientName", {
                rules: [
                  {
                    required: true,
                    message: "Enter the patient's name!",
                  },
                ],
              })(
                <Input
                  onChange={(e) => {
                    setFormFieldsValueHandler(e.target.value, "patientName");

                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   firstName: e.target.value,
                    // }));
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>

        <Col span={22}>
          <div>
            <Form.Item className="input" label="Patient ID">
              {getFieldDecorator("memberID", {
                rules: [
                  { required: true, message: "Enter the Patient ID!" },
                  // {
                  //   pattern: /^[0-9]*$/,
                  //   // max: 100,
                  //   message: "Patient ID should be a number",
                  // },
                ],
              })(
                <Input
                  onChange={(e) => {
                    setFormFieldsValueHandler(e.target.value, "memberID");

                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   memberID: e.target.value,
                    // }));
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>

        <Col span={22}>
          <div>
            <Form.Item className="input" label="Date Of Service">
              {getFieldDecorator("DOS", {
                rules: [
                  {
                    required: true,
                    message: "Enter the Date Of Service!",
                    type: "object",
                    whitespace: true,
                  },
                  //   {
                  //     pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                  //     message: `Please enter correct format MM/DD/YYYY Pattern`
                  // }
                ],
              })(
                <DatePicker
                  //  defaultValue={moment('01/01/2015', dateFormatList[0])}
                  format={dateFormat}
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    setFormFieldsValueHandler(dateString.toString(), "DOS");

                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   DOS: dateString.toString(),
                    // }))
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Form.Item className="input" label="ICN Number">
              {getFieldDecorator("ICN_Number", {
                rules: [
                  { required: true, message: "Enter the patient's ICN Number!" },
                  {
                    pattern: /^[0-9]*$/,
                    // max: 100,
                    message: "ICN number should be a number",
                  },
                ],
              })(
                <Input
                  onChange={(e) => {
                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   ICNNumber: e.target.value,
                    // }));

                    setFormFieldsValueHandler(e.target.value, "ICN_Number");
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Form.Item
              className="multipleSelect"
              label="CPT Codes"
              hasFeedback
              validateStatus={isValidCPTcodes ? undefined : "error"}
              help={customCPTcodeErrMsg ? customCPTcodeErrMsg : undefined}
            >
              {getFieldDecorator("CPT_codes", {
                //   rules: [
                //       { required: false, message: "Enter the patient's CPT Codes!", type: 'array'},  
                //          {
                //           // max: 100,
                //           // message: "City must be maximum 100 characters.",
                //       },
                //   ],
              })(
                <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" onChange={(value) => {
                  if (value.length !== 0) {
                    setCustomCPTcodeErrMsg('');
                    setIsValidCPTcodes(true)
                    setCPTcodes(value)
                  }
                  else {
                    setCustomCPTcodeErrMsg('Enter the CPT Codes!');
                    setIsValidCPTcodes(false)
                    setCPTcodes('')
                  }
                  setFormFieldsValueHandler(value, "CPT_codes")
                }}>
                  {children}
                </Select>
              )}
            </Form.Item>
          </div>
        </Col>

        <Col span={8} offset={16}>
          <div>
            <Form.Item >
              <div >
                <Button type="primary" className='backBtn' style={{ backgroundColor: '#e84c89' }} onClick={() => {
                  props.form.resetFields()
                  setUserDetailsHandler({
                    patientName: "",
                    memberID: "",
                    DOS: "",
                    ICN_Number: "",
                    CPT_codes: "",
                  });
                }}  >
                  CLEAR
                </Button>
              </div>
            </Form.Item>
          </div>
        </Col>
      </div>
    </Col>
  );
}

export default Form.create()(ClaimsReconsideration);
