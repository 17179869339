import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const INNOVATIVE_BASEURL = process.env.INNOVATIVE_BASEURL;


const initialState = {
    insuranceType: [],
    insuranceType_loading: false
};

const insuranceTypeSlice = createSlice({
    name: 'insuranceTypeSlice',
    initialState,
    reducers: {
        fetchInsuranceType_pending ( state, action) {
            console.log("fetchInsuranceType.pending....... ")
        },
        fetchInsuranceType_fulfilled ( state, action) {
          state.insuranceType = action.payload
            console.log("fetchInsuranceType.fulfilled....... ", action.payload)
        },
        fetchInsuranceType_rejected ( state, action) {
            console.log("fetchInsuranceType.rejecyed....... ", action.payload)
        }
    }
  
})

export const fetchInsuranceType = () => {
    return async (dispatch) => {
        dispatch(insuranceTypeSlice.actions.fetchInsuranceType_pending());
      const fetchData = async () => {
        const response = await fetch(`http://localhost:8808/getInsuranceType`)
  
        if (!response.ok) {
          throw new Error('Could not fetch insuranceType data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const insuranceTypeData = await fetchData();
        dispatch(insuranceTypeSlice.actions.fetchInsuranceType_fulfilled(insuranceTypeData));
      } catch (error) {
        dispatch(insuranceTypeSlice.actions.fetchInsuranceType_rejected(error));
      }
    };
  };

export const insuranceTypeActions = insuranceTypeSlice.actions;
export default insuranceTypeSlice