import { createSlice, createAsyncThunk, createReducer } from "@reduxjs/toolkit";

const INNOVATIVE_BASEURL = process.env.INNOVATIVE_BASEURL;


export const SendFormData = createAsyncThunk('sendformDataSlice/SendFormData', async (PDFdata) => {
  {
    const response = await fetch(`http://localhost:8808/appealsData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(PDFdata)
    })
    if (!response.ok) {
      throw new Error('Could not fetch formTemplate data!');
    }
    const data = await response.json();
    return data;
  }
})

const initialState = {
  sentFormDataSuccessfully: false,
  sendingFormData: false,
  sentFormDataFailed: false,
  pdfDownload_loading: false
};

const sendformDataSlice = createSlice({
  name: 'sendformDataSlice',
  initialState,
  reducers: {
    pdfDownload_pending ( state, action) {
      state.pdfDownload_loading = true;
        console.log("pdfDownload.pending....... ")
    },
    pdfDownload_fulfilled ( state, action) {
      state.pdfDownload_loading = false;
        console.log("pdfDownload.fulfilled....... ")
    },
    pdfDownload_rejected ( state, action) {
      state.pdfDownload_loading = false;
        console.log("pdfDownload.rejecyed....... ")
    }
},
  extraReducers: {
    [SendFormData.pending]: (state) => {
      console.log("SendFormData.pending....... ")
      state.sendingFormData = true;
      state.sentFormDataSuccessfully = false;
      state.sentFormDataFailed = false;
    },
    [SendFormData.fulfilled]: (state) => {
      console.log("SendFormData.fulfilled....... ")
      state.sentFormDataSuccessfully = true;
      state.sendingFormData = false;
    },
    [SendFormData.rejected]: (state) => {
      console.log("SendFormData.rejected....... ")
      state.sendingFormData = false;
      state.sentFormDataSuccessfully = false;
      state.sentFormDataFailed = true;
      state.pdfDownload_loading = false;
    }
  }
})

// const sendformDataSlice = createSlice({
//     name: 'sendformDataSlice',
//     initialState,
//     reducers: {
//         fetchGroupName_pending ( state, action) {
//             console.log("SendFormData.pending....... ")
//         },
//         fetchGroupName_fulfilled ( state, action) {
//           state.formTemplate = action.payload
//             console.log("SendFormData.fulfilled....... ", action.payload)
//         },
//         fetchGroupName_rejected ( state, action) {
//             console.log("SendFormData.rejecyed....... ", action.payload)
//         }
//     }

// })

// export const SendFormData = (appealID) => {
//     return async (dispatch) => {
//         dispatch(sendformDataSlice.actions.fetchGroupName_pending());
//       const fetchData = async () => {
//         const response = await fetch(`http://localhost:8808/getFormTemplate?appealID=${appealID}`)

//         if (!response.ok) {
//           throw new Error('Could not fetch formTemplate data!');
//         }

//         const data = await response.json();

//         return data;
//       };

//       try {
//         const groupNameData = await fetchData();
//         dispatch(sendformDataSlice.actions.fetchGroupName_fulfilled(groupNameData));
//       } catch (error) {
//         dispatch(sendformDataSlice.actions.fetchGroupName_rejected(error));
//       }
//     };
//   };

export const formDataActions = sendformDataSlice.actions;
export default sendformDataSlice