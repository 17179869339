import { createSlice, createAsyncThunk, createReducer } from "@reduxjs/toolkit";

const INNOVATIVE_BASEURL = process.env.INNOVATIVE_BASEURL;


export const fetchFormTemplate = createAsyncThunk('formTemplateSlice/fetchFormTemplate', async (appealID) => {
  const response = await fetch(`http://localhost:8808/getFormTemplate?appealID=${appealID}`)
  if (!response.ok) {
    throw new Error('Could not fetch formTemplate data!');
  }
  const data = await response.json();
  return data;

})

const initialState = {
  formTemplate: [],
  formTemplate_loading: false
};

const formTemplateSlice = createSlice({
  name: 'formTemplateSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFormTemplate.pending]: (state) => {
      console.log("fetchFormTemplate.pending....... ")
      state.formTemplate_loading = true;
    },
    [fetchFormTemplate.fulfilled]: (state, { payload }) => {
      console.log("fetchFormTemplate.fulfilled....... ", payload)
      state.formTemplate = payload;
      state.formTemplate_loading = false;
    },
    [fetchFormTemplate.rejected]: (state, { payload }) => {
      console.log("fetchFormTemplate.rejected....... ", payload)
      state.formTemplate_loading = false;
    }
  }
})

// const formTemplateSlice = createSlice({
//     name: 'formTemplateSlice',
//     initialState,
//     reducers: {
//         fetchGroupName_pending ( state, action) {
//             console.log("fetchFormTemplate.pending....... ")
//         },
//         fetchGroupName_fulfilled ( state, action) {
//           state.formTemplate = action.payload
//             console.log("fetchFormTemplate.fulfilled....... ", action.payload)
//         },
//         fetchGroupName_rejected ( state, action) {
//             console.log("fetchFormTemplate.rejecyed....... ", action.payload)
//         }
//     }

// })

// export const fetchFormTemplate = (appealID) => {
//     return async (dispatch) => {
//         dispatch(formTemplateSlice.actions.fetchGroupName_pending());
//       const fetchData = async () => {
//         const response = await fetch(`http://localhost:8808/getFormTemplate?appealID=${appealID}`)

//         if (!response.ok) {
//           throw new Error('Could not fetch formTemplate data!');
//         }

//         const data = await response.json();

//         return data;
//       };

//       try {
//         const groupNameData = await fetchData();
//         dispatch(formTemplateSlice.actions.fetchGroupName_fulfilled(groupNameData));
//       } catch (error) {
//         dispatch(formTemplateSlice.actions.fetchGroupName_rejected(error));
//       }
//     };
//   };

export const groupNameActions = formTemplateSlice.actions;
export default formTemplateSlice