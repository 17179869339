import { configureStore } from "@reduxjs/toolkit";
import  groupNameSlice  from './groupNameSlice';
import  appealTypeSlice  from './appealTypeSlice';
import  insuranceTypeSlice  from './insuranceTypeSlice';
import  formTemplateSlice  from './formTemplateSlice';
import  sendformDataSlice  from './sendformDataSlice';
// import  pdfDownloadSlice  from './pdfDownloadSlice';


const store = configureStore({
    reducer: {
           groupName: groupNameSlice.reducer,
            appelType : appealTypeSlice.reducer,
            insuranceType : insuranceTypeSlice.reducer,
            formTemplate : formTemplateSlice.reducer,
            sendformDataSlice : sendformDataSlice.reducer,
            // pdfDownloadSlice : pdfDownloadSlice.reducer,
    }
})

export default store;

