import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const INNOVATIVE_BASEURL = process.env.INNOVATIVE_BASEURL;


const initialState = {
    groupName: [],
    groupName_loading: false
};

const groupNameSlice = createSlice({
    name: 'groupNameSlice',
    initialState,
    reducers: {
        fetchGroupName_pending ( state, action) {
            console.log("fetchGroupName.pending....... ")
        },
        fetchGroupName_fulfilled ( state, action) {
          state.groupName = action.payload
            console.log("fetchGroupName.fulfilled....... ", action.payload)
        },
        fetchGroupName_rejected ( state, action) {
            console.log("fetchGroupName.rejecyed....... ", action.payload)
        }
    }
  
})

export const fetchGroupName = () => {
    return async (dispatch) => {
        dispatch(groupNameSlice.actions.fetchGroupName_pending());
      const fetchData = async () => {
        const response = await fetch(`http://localhost:8808/getGroupType`)
  
        if (!response.ok) {
          throw new Error('Could not fetch groupName data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const groupNameData = await fetchData();
        dispatch(groupNameSlice.actions.fetchGroupName_fulfilled(groupNameData));
      } catch (error) {
        dispatch(groupNameSlice.actions.fetchGroupName_rejected(error));
      }
    };
  };

export const groupNameActions = groupNameSlice.actions;
export default groupNameSlice