import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Form,
  Select,
  Col,
  Input,
  Divider,
  Layout,
  DatePicker,
} from "antd";


import "../../userDetails/styles.css";

function Customtemplate(props) {
  const {
    checkEverythingFilledOut,
    // setIsAllFieldsFilled,
    userDetails,
    setUserDetailsHandler,
  } = props;
  const { Header, Content, Sider } = Layout;
  const dateFormat = "MM/DD/YYYY";

  const { getFieldDecorator } = props.form;

  useEffect(() => {
    setUserDetailsHandler({
      firstName: "",
      lastName: "",
      patientID: "",
      DOB: "",
      DOS: "",
      claimID: "",
    });
  }, []);

  return (
    <Col span={12} style={{ borderRight: "1px solid #cfcfcf" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Col span={22}>
          <div>
            <Form.Item className="input" label="First Name">
              {getFieldDecorator("firstName", {
                rules: [
                  {
                    required: true,
                    message: "please the patient's First name!",
                  },
                ],
              })(
                <Input
                  onChange={(e) => {
                    checkEverythingFilledOut(e.target.value, "firstName");

                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   firstName: e.target.value,
                    // }));
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Form.Item className="input" label="Last Name">
              {getFieldDecorator("lastName", {
                rules: [
                  {
                    required: true,
                    message: "please the patient's Last name!",
                  },
                ],
              })(
                <Input
                  onChange={(e) => {
                    checkEverythingFilledOut(e.target.value, "lastName");

                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   lastName: e.target.value,
                    // }));
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>

        <Col span={22}>
          <div>
            <Form.Item className="input" label="Patient ID">
              {getFieldDecorator("patientID", {
                rules: [
                  { required: true, message: "Enter the Patient ID!" },
                  {
                    // max: 100,
                    // message: "City must be maximum 100 characters.",
                  },
                ],
              })(
                <Input
                  onChange={(e) => {
                    checkEverythingFilledOut(e.target.value, "patientID");

                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   patientID: e.target.value,
                    // }));
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Form.Item className="input" label="Date Of Birth">
              {getFieldDecorator("DOB", {
                rules: [
                  {
                    required: true,
                    message: "Enter the patient's Date Of Birth!",
                    type: "object",
                    whitespace: true,
                  },
                  // {
                  //   // max: 100,
                  //   // message: "City must be maximum 100 characters.",
                  // },
                ],
              })(
                <DatePicker
                  //  defaultValue={moment('01/01/2015', dateFormatList[0])}
                  format={dateFormat}
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    console.log(date);
                    console.log(dateString);
                    console.log(1);
                    checkEverythingFilledOut(dateString.toString(), "DOB");

                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   DOB: dateString.toString(),
                    // }))
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>
        {/* <Col span={22}>
                            <div>
                              <Form.Item
                                className="input"
                                label="Date Of Birth"

                              >
                                {getFieldDecorator("DOB", {
                                  rules: [
                                    { required: true, message: "Enter the patient's Date Of Birth!" },
                                    {
                                      pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                                      message: `Please enter correct format MM/DD/YYYY Pattern`
                                  }
                                  ],
                                })(
                                  <InputMask
                                  mask="99/99/9999"
                                  // disabled
                                  placeholder='MM/DD/YYYY'
                                  alwaysShowMask
                                  maskChar={null}
                                  className="ant-input width-100"
                                  render={(ref, props) => (
                                    <Input
                                    onChange={(e) => {
                                      checkEverythingFilledOut()

                                      setUserDetails((prevState) => ({
                                        ...prevState,
                                        DOB: e.target.value,
                                      }));
                                    }}
                                  />
                                  )}
                              />
                                  
                                )}
                              </Form.Item>
                            </div>
                          </Col> */}
        <Col span={22}>
          <div>
            <Form.Item className="input" label="Date Of Submission">
              {getFieldDecorator("DOS", {
                rules: [
                  {
                    required: true,
                    message: "Enter the Date Of Submission!",
                    type: "object",
                    whitespace: true,
                  },
                  //   {
                  //     pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                  //     message: `Please enter correct format MM/DD/YYYY Pattern`
                  // }
                ],
              })(
                <DatePicker
                  //  defaultValue={moment('01/01/2015', dateFormatList[0])}
                  format={dateFormat}
                  style={{ width: "100%" }}
                  onChange={(date, dateString) => {
                    checkEverythingFilledOut(dateString.toString(), "DOS");

                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   DOS: dateString.toString(),
                    // }))
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>
        <Col span={22}>
          <div>
            <Form.Item className="input" label="Claim ID">
              {getFieldDecorator("claimID", {
                rules: [
                  { required: true, message: "Enter the patient's Claim ID!" },
                  {
                    // max: 100,
                    // message: "City must be maximum 100 characters.",
                  },
                ],
              })(
                <Input
                  onChange={(e) => {
                    // setUserDetails((prevState) => ({
                    //   ...prevState,
                    //   claimID: e.target.value,
                    // }));

                    checkEverythingFilledOut(e.target.value, "claimID");
                  }}
                />
              )}
            </Form.Item>
          </div>
        </Col>
      </div>
    </Col>
  );
}

export default Form.create()(Customtemplate);
