import React, { useEffect, useState } from "react";
import { Button, Row, Form, Select, Col, Input, Divider, Layout, DatePicker } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderComponent from "../Header/index";

import { pdf } from '@react-pdf/renderer'
import { saveAs } from "file-saver";

// import { appealType, Insurance, GroupName, form_template } from '../../hospitals';


import "./styles.css";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import moment from "moment";


import Customtemplate from '../template/initialTemplate/index'
import TFL from '../template/TFL/index'
import TLF_PreviewComponent from '../template/TFL/TLF_preview'
import TFL_PdfTemplate from '../template/TFL/TFL_PdfTemplate'

import ClaimsReconsideration from '../template/Claims Reconsideration/index'
import ClaimsReconsiderationPdfTemplate from '../template/Claims Reconsideration/ClaimsReconsiderationPdf'
import ClaimsPreview from '../template/Claims Reconsideration/claimsPreview'

import CMS_and_Medicaid from '../template/CMS&Medicaid/index'
import CMS_and_MedicaidPdfTemplate from '../template/CMS&Medicaid/CMS&MedicaidPdf'
import CMS_and_MedicaidPreview from '../template/CMS&Medicaid/CMS&MedicaidPreview'
import { fetchGroupName } from "../../features/groupNameSlice";
import { fetchAppealType } from "../../features/appealTypeSlice";
import { fetchInsuranceType } from "../../features/insuranceTypeSlice";
import { fetchFormTemplate } from "../../features/formTemplateSlice";
import { SendFormData } from "../../features/sendformDataSlice";


import { formDataActions } from "../../features/sendformDataSlice";


function UserDetailsComponent(props) {
  const { Header, Content, Sider } = Layout;
  const dateFormat = 'MM/DD/YYYY';
  const FormFieldValidation = React.useRef(null);
  const clearFieldValue = React.useRef(null);

  const { getFieldDecorator } = props.form;

  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("location", state);

  const dispatch = useDispatch();
  const groupNameReducerState = useSelector((state) => state.groupName.groupName);
  const appealTypeReducerState = useSelector((state) => state.appelType.appealType);
  const insuranceReducerState = useSelector((state) => state.insuranceType.insuranceType);
  const formTemplateReducerState = useSelector((state) => state.formTemplate.formTemplate);
  const isFormTemplateLoadingReducerState = useSelector((state) => state.formTemplate.formTemplate_loading);

  const pdfDownloading = useSelector((state) => state.sendformDataSlice.pdfDownload_loading);

  const sentFormDataSuccessfullyReducerState = useSelector((state) => state.sendformDataSlice.sentFormDataSuccessfully);
  const sendingFormDataReducerState = useSelector((state) => state.sendformDataSlice.sendingFormData);
  const sentFormDataFailedReducerState = useSelector((state) => state.sendformDataSlice.sentFormDataFailed);

  const [getGroupNameState, setGetGroupNameState] = useState([]);
  const [getInsuranceState, setGetInsuranceState] = useState([]);
  const [getAppealTypeState, setGetAppealTypeState] = useState([]);


  const [selectedGroupIDState, setSelectedGroupIDState] = useState('');
  const [currentDateState, setCurrentDate] = useState(moment(new Date()).format("MM/DD/YYYY"));
  const [isPreviewButtonVisibleState, setIsPreviewButtonVisibleState] = useState(false);
  const [userDetailsState, setUserDetailsState] = useState({});
  const [getFormTemplateState, setGetFormTemplateState] = useState(undefined);


  useEffect(() => {
    
  }, [userDetailsState])

  useEffect(() => {
    if (groupNameReducerState.length == 0) {
      dispatch(fetchGroupName());
    }
    if (insuranceReducerState.length == 0) {
      dispatch(fetchInsuranceType());
    }
    if (appealTypeReducerState.length == 0) {
      dispatch(fetchAppealType());
    }

  }, [dispatch])

  const setFormFieldsValue = (value, field) =>{
    value = (field === "CPT_codes") ? value.toString().replaceAll(',', ', ') : value
    setUserDetailsState((prevState) => ({ ...prevState, [field]: value }))
  }
  //   useEffect( () => {
  //     if (groupNameReducerState.length != 0) {
  //         console.log("GotGroupNAme in the userDetails component " , groupNameReducerState);
  //         setGetGroupNameState(groupNameReducerState)
  //     }

  // } ,[groupNameReducerState] )

  // useEffect( () => {
  //     if (insuranceReducerState.length != 0) {
  //         console.log("GOT INSURANCETYPE in the userDetails component " , insuranceReducerState);
  //         setGetInsuranceState(insuranceReducerState)
  //     }

  // } ,[insuranceReducerState] )

  // useEffect( () => {
  //     if (appealTypeReducerState.length != 0) {
  //         console.log("GotGroupNAme in the userDetails component " , appealTypeReducerState);
  //         setGetAppealTypeState(appealTypeReducerState)
  //     }

  // } ,[ appealTypeReducerState] )

  useEffect(() => {

    if (getAppealTypeState && (!formTemplateReducerState && !isFormTemplateLoadingReducerState)) {

      if (getAppealTypeState.appealID) dispatch(fetchFormTemplate(getAppealTypeState.appealID))
    }

  }, [getAppealTypeState])

  useEffect(() => {
    if (getAppealTypeState && formTemplateReducerState.length != 0 && !isFormTemplateLoadingReducerState) {
      setGetFormTemplateState(formTemplateReducerState[0]);
    }
  }, [formTemplateReducerState, isFormTemplateLoadingReducerState])


  useEffect(() => {
    if (sentFormDataSuccessfullyReducerState) {
      const getPDFComponent = renderDocumentFile();
      const getPDFFileName = getFileName();
      pdf(getPDFComponent)
        .toBlob()
        .then((blob) => {
          saveAs(blob, getPDFFileName)
          clearFieldValue.current();
          dispatch(formDataActions.pdfDownload_fulfilled())
        });
    }
  }, [sentFormDataSuccessfullyReducerState])


  useEffect(() => {
    if (state && !isFormTemplateLoadingReducerState) {
      let getSelectedGroupID = state.selectedGroupID;

      let appealTypeID = state.val.appealType;
      let insuranceId = state.val.insuranceType;
      let gruopNameID = state.val.groupName;

      let getAppealTypeData,
        getInsuranceData,
        getGroupNameData,
        getFormTemplate = "";

      if (appealTypeID) {
        getAppealTypeData = appealTypeReducerState.filter((e) => {
          return e.appealID === appealTypeID;
        });
      }
      if (insuranceId) {
        getInsuranceData = insuranceReducerState.filter((e) => {
          return e.insuranceID === insuranceId;
        });
      }

      if (gruopNameID) {
        getGroupNameData = groupNameReducerState.filter((e) => {
          return e.ID === gruopNameID;
        });
      }

      if (appealTypeID && formTemplateReducerState.length != 0) {
        // getFormTemplate = formTemplateReducerState.filter((e) => {
        //   return e.appealID === appealTypeID;
        // });
        setGetFormTemplateState(formTemplateReducerState[0]);
      }

      setSelectedGroupIDState(getSelectedGroupID);
      setGetAppealTypeState(getAppealTypeData[0]);
      setGetInsuranceState(getInsuranceData[0]);
      setGetGroupNameState(getGroupNameData[0]);
    }
  }, [state, isFormTemplateLoadingReducerState]);



  const renderFormFields = () => {

    if (selectedGroupIDState === 111 && getAppealTypeState.appealID === 100031) {
      return <ClaimsReconsideration
        userDetails={userDetailsState}
        setUserDetailsHandler={(userData) => setUserDetailsState(userData)}
        getAppealType={getAppealTypeState}
        getFormTemplate={getFormTemplateState}
        FormFieldValidationHandler = {FormFieldValidation}
        setFormFieldsValueHandler = {setFormFieldsValue}
        currentDate={currentDateState}
        clearFieldValueHandler={clearFieldValue}
      />
    } else if (selectedGroupIDState === 111) {
      return <TFL
        userDetails={userDetailsState}
        setUserDetailsHandler={(userData) => setUserDetailsState(userData)}
        getAppealType={getAppealTypeState}
        getFormTemplate={getFormTemplateState}
        FormFieldValidationHandler = {FormFieldValidation}
        setFormFieldsValueHandler = {setFormFieldsValue}
        currentDate={currentDateState}
        clearFieldValueHandler={clearFieldValue}
      />
    }
    else if (selectedGroupIDState === 222) {
      return <CMS_and_Medicaid
        userDetails={userDetailsState}
        setUserDetailsHandler={(userData) => setUserDetailsState(userData)}
        getAppealType={getAppealTypeState}
        getFormTemplate={getFormTemplateState}
        FormFieldValidationHandler = {FormFieldValidation}
        setFormFieldsValueHandler = {setFormFieldsValue}
        currentDate={currentDateState}
        clearFieldValueHandler={clearFieldValue}
      />
    }

  }
  const renderPreviewComponent = () => {
    if (formTemplateReducerState.length != 0) {


      if (selectedGroupIDState === 111 && getAppealTypeState.appealID === 100031) {
        return <ClaimsPreview
          getAppealType={getAppealTypeState}
          getInsurance={getInsuranceState}
          getGroupName={getGroupNameState}
          userDetails={userDetailsState}
          currentDate={currentDateState}
          getFormTemplate={getFormTemplateState}
          setFormFieldsValueHandler = {setFormFieldsValue}
        />
      } else if (selectedGroupIDState === 111) {
        return <TLF_PreviewComponent
          getAppealType={getAppealTypeState}
          getInsurance={getInsuranceState}
          getGroupName={getGroupNameState}
          userDetails={userDetailsState}
          currentDate={currentDateState}
          getFormTemplate={getFormTemplateState}
          setFormFieldsValueHandler = {setFormFieldsValue}
        />
      }
      else if (selectedGroupIDState === 222) {
        return <CMS_and_MedicaidPreview
          getAppealType={getAppealTypeState}
          getInsurance={getInsuranceState}
          getGroupName={getGroupNameState}
          userDetails={userDetailsState}
          currentDate={currentDateState}
          getFormTemplate={getFormTemplateState}
          setFormFieldsValueHandler = {setFormFieldsValue}
        />
      }
    }

  }
  const renderDocumentFile = () => {
    if (formTemplateReducerState.length != 0) {
      if (selectedGroupIDState === 111 && getAppealTypeState.appealID === 100031) {
        return <ClaimsReconsiderationPdfTemplate getAppealType={getAppealTypeState} getInsurance={getInsuranceState} getGroupName={getGroupNameState} userDetails={userDetailsState} currentDate={currentDateState} getFormTemplate={getFormTemplateState} setFormFieldsValueHandler = {setFormFieldsValue} />
      } else if (selectedGroupIDState === 111) {
        return <TFL_PdfTemplate getAppealType={getAppealTypeState} getInsurance={getInsuranceState} getGroupName={getGroupNameState} userDetails={userDetailsState} currentDate={currentDateState} getFormTemplate={getFormTemplateState} setFormFieldsValueHandler = {setFormFieldsValue}/>
      }
      else if (selectedGroupIDState === 222) {
        return <CMS_and_MedicaidPdfTemplate
          getAppealType={getAppealTypeState}
          getInsurance={getInsuranceState}
          getGroupName={getGroupNameState}
          userDetails={userDetailsState}
          currentDate={currentDateState}
          getFormTemplate={getFormTemplateState}
          setFormFieldsValueHandler = {setFormFieldsValue}
        />
      }
    }

  }
  const getFileName = () => {
    if (formTemplateReducerState.length != 0) {
      if (selectedGroupIDState === 111 && getAppealTypeState.appealID === 100031) {
        return `${userDetailsState.patientName}-${userDetailsState.memberID}`
      } else if (selectedGroupIDState === 111) {
        return `${userDetailsState.patientName}-${userDetailsState.memberID}`
      }
      else if (selectedGroupIDState === 222) {
        return `${userDetailsState.patientName}-${userDetailsState.memberID}`
      }
    }
  }



  const handleSubmit = (e) => {
    // alert('workind')
    e.preventDefault();


    FormFieldValidation.current();
    // props.form.validateFieldsAndScroll((err, values) => {
    //   if (!err) {
    //     // setIsPreviewButtonVisibleState(true)
    //     let formData = {
    //       formID: getFormTemplateState.formID,
    //       patientName: userDetailsState.patientName ? userDetailsState.patientName : null,
    //       memberID: userDetailsState.memberID ? userDetailsState.memberID : null,
    //       DOB: userDetailsState.DOB ? userDetailsState.DOB : null,
    //       dateOfSubmission: currentDateState ? currentDateState : null,
    //       claimNumber: userDetailsState.claimNumber ? userDetailsState.claimNumber : null,
    //       dateOfService: userDetailsState.DOS ? userDetailsState.DOS : null,
    //       ICNnumber: userDetailsState.ICN_Number ? userDetailsState.ICN_Number : null,
    //       CPTcodes: userDetailsState.CPT_codes ? userDetailsState.CPT_codes : null,
    //     }
    //     dispatch(SendFormData(formData))

      // }
    // });
  };


  return (formTemplateReducerState.length != 0 &&
    <div>
      <div className="root">
        <Layout>
          <HeaderComponent />

          {/* <PDFViewer style={{ width: "100%", height: "8500px" }}>
            <TFL_PdfTemplate
              getAppealType={getAppealTypeState}
              getInsurance={getInsuranceState}
              getGroupName={getGroupNameState}
              userDetails={userDetailsState}
              currentDate={currentDateState}
            />
          </PDFViewer> */}


          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <div className="content center-start">
                {/* <div className="inner-title">
                  Step 2
                </div> */}
                <Form layout="vertical" className="content-center" style={{ width: '100%', padding: '20px 1%' }} onSubmit={handleSubmit}>
                  <div className="innerContent content-center">
                    <div className="inner-title" style={{ marginBottom: '25px' }}>
                      User Details
                    </div>


                    <Row style={{ display: 'flex', justifyContent: 'space-between' }} >
                      {renderFormFields()}

                      <Col span={12}>
                        {renderPreviewComponent()}
                        {/* <PreviewComponent
                          getAppealType={getAppealTypeState}
                          getInsurance={getInsuranceState}
                          getGroupName={getGroupNameState}
                          userDetails={userDetailsState}
                          currentDate={currentDateState}
                        /> */}
                      </Col>
                    </Row>
                  </div>
                  <div className="innerContent " style={{ borderTop: '0px', padding: "0px" }}>
                    <Row className="footer">
                      <Col span={8}>
                        <div style={{ textAlign: 'left' }}>
                          <Button type="primary" className='backBtn' onClick={() => { navigate(-1) }}
                          >
                            BACK
                          </Button>
                        </div>

                      </Col>
                      <Col span={8}>
                        {/* <div className="completeDiv">
                      0% Complete
                    </div> */}
                      </Col>
                      <Col span={8}>
                        <div style={{ textAlign: 'right' }}>
                          {/* <PDFDownloadLink
                            document= {isAllFieldsFilled && renderDocumentFile() }
                            fileName={getFileName()}
                          >
                            {({ loading }) => {
                              return loading ?
                                (<Button type="primary"
                                  className='nextBtn' style={{ backgroundColor: '#e84c8970' }}  >
                                  LOADING...
                                </Button>
                                ) :
                                (<Button type="primary"
                                  disabled={!isAllFieldsFilled}
                                  className={!isAllFieldsFilled ? 'nextBtn disabledBtn' : 'nextBtn'}
                                  style={!isAllFieldsFilled ? { backgroundColor: '#e84c8970' } : { backgroundColor: '#e84c89' }}
                                  onClick={() => alert('working')}
                                >
                                  DOWNLOAD
                                </Button>
                                )
                            }
                            }
                          </PDFDownloadLink> */}

                          <Button type="primary" htmlType="submit"
                            loading={sendingFormDataReducerState || pdfDownloading}
                            // disabled={!isAllFieldsFilled}
                            className='nextBtn'
                            style={{ backgroundColor: '#e84c89' }}
                            // onClick={() => alert('working')}
                          >
                            {pdfDownloading ? 'Downloading...' : 'DOWNLOAD'}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div >
    </div >

  );
}

export default Form.create()(UserDetailsComponent);
