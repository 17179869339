import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const INNOVATIVE_BASEURL = process.env.INNOVATIVE_BASEURL;


const initialState = {
    appealType: [],
    appealType_loading: false
};

const appealTypeSlice = createSlice({
    name: 'appealTypeSlice',
    initialState,
    reducers: {
        fetchAppealType_pending ( state, action) {
            console.log("fetchAppealType.pending....... ")
        },
        fetchAppealType_fulfilled ( state, action) {
          state.appealType = action.payload
            console.log("fetchAppealType.fulfilled....... ", action.payload)
        },
        fetchAppealType_rejected ( state, action) {
            console.log("fetchAppealType.rejecyed....... ", action.payload)
        }
    }
  
})

export const fetchAppealType = () => {
    return async (dispatch) => {
        dispatch(appealTypeSlice.actions.fetchAppealType_pending());
      const fetchData = async () => {
        const response = await fetch(`http://localhost:8808/getAppealType`)
  
        if (!response.ok) {
          throw new Error('Could not fetch appealType data!');
        }
  
        const data = await response.json();
  
        return data;
      };
  
      try {
        const appealTypeData = await fetchData();
        dispatch(appealTypeSlice.actions.fetchAppealType_fulfilled(appealTypeData));
      } catch (error) {
        dispatch(appealTypeSlice.actions.fetchAppealType_rejected(error));
      }
    };
  };

export const appealTypeActions = appealTypeSlice.actions;
export default appealTypeSlice