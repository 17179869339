import React, { useEffect, useState } from 'react';
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Button, Row, Form, Select, Col, Input, Divider } from 'antd';


import times_new_roman_bold from '../../../assets/fonts/TimesNewRoman/timesNewRomanBold.ttf'
import times_new_roman_bold_italic from '../../../assets/fonts/TimesNewRoman/timesNewRomanBoldItalic.ttf'
import times_new_roman_italic from '../../../assets/fonts/TimesNewRoman/timesNewRomanItalic.ttf'
import times_new_roman from '../../../assets/fonts/TimesNewRoman/timesNewRoman.ttf'

import CentersLaboratory from '../../../assets/CentersLaboratory.png'


Font.register({ family: 'timesNewRomanBold', format: "truetype", src: times_new_roman_bold });
Font.register({ family: 'timesNewRomanBoldItalic', format: "truetype", src: times_new_roman_bold_italic });
Font.register({ family: 'timesNewRomanItalic', format: "truetype", src: times_new_roman_italic });
Font.register({ family: 'timesNewRoman', format: "truetype", src: times_new_roman });

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff',
        width: '100%',
        border: '1px solid #595959',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 50,
        fontFamily: "timesNewRoman"
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    container: {

        display: 'flex',
        flex: 1,
    },
    section: {
        marginTop: 10,

        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        flexShrink: 0

    },
    blockSection: {
        marginTop: 10,

        display: 'block',
        // flexWrap: 'wrap',
        // flexDirection: 'row',
        // flexShrink: 0

    },
    contentSection: {
        marginVertical: 10,
    },
    margin_top_10: {
        marginTop: 10
    },
    margin_top_15: {
        marginTop: 15
    },
    margin_top_20: {
        marginTop: 20
    },
    margin_top_5: {
        marginTop: 5
    },
    margin_bottom: {
        marginBottom: 10
    },
    logo_Container: {

        flexGrow: 1,
        padding: 10,
        marginBottom: 10

    },

    image: {
        width: '40%',
    },

    headerContainer: {
        flexGrow: 1,
        // padding: 10,
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 17,
        marginTop: 10,
        display: 'flex',
        // flexWrap: 'nowrap',
        // flexDirection: 'row',
        // flexShrink: 0
    },
    headerPrimaryText: {

        // fontWeight: 'bold',
        fontSize: 12,
        // lineHeight: 1.5
    },
    divider: {
        width: '100%',
        marginHorizontal: 3,
        marginVertical: 10,
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#595959',
        opacity: 0.6
    },

    detailsPrimaryText_bold: {
        fontFamily: "timesNewRomanBold",
        fontWeight: 'bold',
        fontSize: 11,
        marginBottom: 3,
        color: '#000'
    },
    detailsPrimaryText: {
        fontFamily: "timesNewRoman",
        fontSize: 11,
        marginBottom: 3,
        color: '#000'
    },
    detailsScondaryText: {
        fontFamily: "timesNewRoman",
        fontSize: 11,
        color: "#000",
    },
    contentPrimaryText: {
        fontFamily: "timesNewRoman",
        fontWeight: 'bold',
        fontSize: 12,
        marginBottom: 3,
        color: '#000'
    },
    contentPrimaryText_bold: {
        fontFamily: "timesNewRomanBold",
        fontWeight: 'bold',
        fontSize: 12,
        marginBottom: 3,
        color: '#000'
    },

    Container_left: {
        width: '50%',
        paddingBottom: 10,
        paddingTop: 10,
    },
    Container_right: {
        width: '50%',
        padding: 10,
        flexDirection: 'column',
        wordWrap: 'break-word',
        alignItems: 'flex-end',
        textAlign: 'right'
    }

});

// Create Document Component
const ExportPDF = (props) => {

    const {
        getAppealType,
        getInsurance,
        getGroupName,
        userDetails,
        currentDate,
        // isAllFieldsFilled,
        getFormTemplate
    } = props;
    const [getUserDetails, setGetUserDetails] = useState({})

    useEffect(() => {
        if (userDetails.length != 0) {
            setGetUserDetails(userDetails)
        }

        // return () => {
        //   second
        // }
    }, [userDetails])


    const original_TFLcontent = () => {
        return (
            <div style={styles.margin_top_20} >
                {getFormTemplate.contentOne && <div style={styles.contentSection} >
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentOne}
                    </Text>
                </div>}

                {getFormTemplate.contentTwo &&
                    <div style={styles.margin_top_5}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentTwo}
                        </Text>
                    </div>
                }
                {getFormTemplate.contentThree &&
                    <div style={styles.margin_top_5}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentThree}
                        </Text>
                    </div>}

                {getFormTemplate.contentFour &&
                    <div style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentFour}
                        </Text>
                    </div>}

                {getFormTemplate.contentFive &&
                    <div style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentFive}
                        </Text>
                    </div>}

                {getFormTemplate.contentSix &&
                    <div style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentSix}
                        </Text>
                    </div>}
                {getFormTemplate.contentSeven &&
                    <div style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentSeven}
                        </Text>
                    </div>}
                {getFormTemplate.contentEight &&
                    <div style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentEight}
                        </Text>
                    </div>}
                {getFormTemplate.contentNine &&
                    <div style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentNine}
                        </Text>
                    </div>}
                {getFormTemplate.contentTen &&
                    <div style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentTen}
                        </Text>
                    </div>}
                {getFormTemplate.contentEleven &&
                    <div style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentEleven}
                        </Text>
                    </div>}

            </div>
        )
    }

    const delay_TFLcontent = () => {
        return (
            <View style={styles.margin_top_20} >
                {getFormTemplate.contentOne &&
                    <View style={styles.contentSection} >
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentOne}
                        </Text>
                    </View>}

                {getFormTemplate.contentTwo &&
                    <View style={styles.margin_top_5}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentTwo}
                        </Text>
                    </View>}

                {getFormTemplate.contentThree &&
                    <View style={styles.margin_top_5}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentThree}
                        </Text>
                    </View>}

                {getFormTemplate.contentFour && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentFour}
                    </Text>
                </View>}

                {getFormTemplate.contentFive && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentFive}
                    </Text>
                </View>}


                {getFormTemplate.contentSix && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentSix}
                    </Text>
                </View>}


                {getFormTemplate.contentSeven && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentSeven}
                    </Text>
                </View>}

                {getFormTemplate.contentEight && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentEight}
                    </Text>
                </View>}
                {getFormTemplate.contentNine && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentNine}
                    </Text>
                </View>}
                {getFormTemplate.contentTen && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentTen}
                    </Text>
                </View>}
                {getFormTemplate.contentEleven && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentEleven}
                    </Text>
                </View>}

            </View>
        )
    }


    const elderplancontent = () => {
        return (
            <View style={styles.margin_top_20} >
                {getFormTemplate.contentOne && <View style={styles.contentSection} >
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentOne}
                    </Text>
                </View>}

                <View style={styles.margin_top_5}>
                    {getFormTemplate.contentTwo &&
                        // {/* <View style={styles.blockSection}> */}
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentTwo}
                        </Text>
                        // {/* </View> */}
                    }

                    {getFormTemplate.contentThree && <View style={styles.margin_top_5}>
                        <Text style={styles.contentPrimaryText}>
                            {getFormTemplate.contentThree}
                        </Text>
                    </View>}
                </View>

                {getFormTemplate.contentFour && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentFour}
                    </Text>
                </View>}

                {getFormTemplate.contentFive && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentFive}
                    </Text>
                </View>}

                {getFormTemplate.contentSix && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentSix}
                    </Text>
                </View>}


                {getFormTemplate.contentSeven && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentSeven}
                    </Text>
                </View>}

                {getFormTemplate.contentEight && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentEight}
                    </Text>
                </View>}

                {getFormTemplate.contentNine && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentNine}
                    </Text>
                </View>}

                {getFormTemplate.contentTen && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentTen}
                    </Text>
                </View>}
                
                {getFormTemplate.contentEleven && <View style={styles.margin_top_15}>
                    <Text style={styles.contentPrimaryText}>
                        {getFormTemplate.contentEleven}
                    </Text>
                </View>}

            </View>
        )
    }

    return (


        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View style={styles.section} fixed>

                        <View style={styles.headerContainer}>
                            <View style={styles.logo_Container}>
                                <Image style={styles.image} src={CentersLaboratory} />
                            </View>
                            {/* <Text style={styles.headerPrimaryText}>
                                {getGroupName.name ? getGroupName.name : ''}
                            </Text> */}
                            <Text style={styles.headerPrimaryText}>
                                {getGroupName.addressline1 ? getGroupName.addressline1 : ''}
                            </Text>
                            <Text style={styles.headerPrimaryText}>
                                {getGroupName.addressline2 ? getGroupName.addressline2 : ''}
                            </Text>
                            <Text style={styles.headerPrimaryText}>
                                {getGroupName.phone ? getGroupName.phone : ''}
                            </Text>
                        </View>
                    </View>


                    {/* <View style={styles.divider} fixed> </View> */}

                    <View style={styles.section} >
                        <View style={styles.Container_left}>
                            <View>
                                <Text style={styles.detailsScondaryText}>
                                    {getAppealType.insuranceType ? getAppealType.insuranceType : ''}
                                </Text>
                            </View>
                            <View>
                                <Text style={styles.detailsScondaryText}>{getAppealType.addressline1 ? getAppealType.addressline1 : ''} </Text>
                            </View>
                            <View>
                                <Text style={styles.detailsScondaryText}>{getAppealType.addressline2 ? getAppealType.addressline2 : ''} </Text>
                            </View>
                            {getAppealType.addressline3 ? <View>
                                <Text style={styles.detailsScondaryText}>{getAppealType.addressline3} </Text>
                            </View> : ""}
                            {getFormTemplate.RE &&
                                <View style={styles.margin_top_10}>
                                    <Text style={styles.detailsPrimaryText}>Re: {getFormTemplate.RE} </Text>
                                </View>}

                        </View>

                        <View style={styles.Container_right}>
                            <Text style={styles.detailsPrimaryText}>
                                DATE : {currentDate}
                            </Text>

                        </View>


                    </View>

                    {/* PERSONAL DETAILS */}
                    {/* <View style={styles.section} > */}
                    <View >
                        <Text style={styles.detailsPrimaryText} >
                            Patient Name: {" "} {userDetails.patientName ? ` ${userDetails.patientName}` : ''}
                        </Text>
                        {/* <Text style={styles.primaryPreviewDetialsText} >
                {getUserDetails ? getUserDetails.firstName : ''} {getUserDetails ? getUserDetails.lastName : ''}
            </Text> */}
                    </View>
                    <View>
                        <Text
                            style={styles.detailsPrimaryText}>
                            Member ID: {" "} {userDetails.memberID ? ` ${userDetails.memberID}` : ''}
                        </Text>
                    </View>
                    <View>
                        <Text
                            style={styles.detailsPrimaryText}>
                            Date Of Service: {" "} {userDetails.DOS ? ` ${userDetails.DOS}` : ''}
                        </Text>
                    </View>
                    <View>
                        <Text
                            style={styles.detailsPrimaryText}>
                            Claim Number: {" "} {userDetails.claimNumber ? ` ${userDetails.claimNumber}` : ''}
                        </Text>
                    </View>
                    {/* </View> */}


                    {/* <View style={styles.margin_top_20} >
                    <View style={styles.contentSection} >
                        <Text style={styles.contentPrimaryText}>
                        To Whom It May Concern:
                        </Text>
                    </View>

                    <View style={styles.margin_top_5}>
                        <Text style={styles.contentPrimaryText}>
                        We are in receipt of your timely filing denial of the Independent Laboratory service, in reference to the above mentioned patient.
                        </Text>
                    </View>
                    <View style={styles.margin_top_5}>
                        <Text style={styles.contentPrimaryText}>
                        Please accept this letter as a formal appeal of this denial.
                        </Text>
                    </View>

                    <View style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                        Patient missed to provide the insurance details at the time of the service visit. Later we got the insurance information from the patient and submitted the claim to UHC insurance, but we received the denial from insurance as the filing limit has expired.
                        </Text>
                    </View>

                    <View style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                        After review of the supporting documentation confirming the appropriate timely filing submission, we respectfully request that this claim be reprocessed and make the payments.
                        </Text>
                    </View>
                    <View style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                        If you have any questions or concerns, please feel free to contact me at (718)534-0689 Ext.325
                        </Text>
                    </View>
                    <View style={styles.margin_top_15}>
                        <Text style={styles.contentPrimaryText}>
                        Thank you in advance for your prompt review payment of this claim.
                        </Text>
                    </View>

                    </View> */}

                    {getAppealType.appealID === 100011 ? delay_TFLcontent() : getAppealType.appealID === 100012 ? original_TFLcontent() : elderplancontent()}


                    <View style={[styles.contentSection, styles.margin_top_15]}>
                        <Text style={styles.detailsPrimaryText}>
                            Thank You
                        </Text>

                        <Text style={styles.detailsPrimaryText}>
                            {getFormTemplate.billingDept}
                        </Text>

                        <Text style={styles.detailsPrimaryText}>
                            Billing Department
                        </Text>
                    </View>

                </View>

                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    totalPages > 1 ? `${pageNumber} / ${totalPages}` : ''
                )} fixed />

            </Page>
        </Document>



    );
}

export default ExportPDF;