import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import "./styles.css";
import "antd/dist/antd.css";
import { Form, Input, Button, notification, Layout, Radio, Row, Col, message, Select } from "antd";
import { FormInstance } from 'antd/lib/form';
import HeaderComponent from "../Header/index";

import { connect } from 'react-redux';
import { fetchGroupName } from '../../features/groupNameSlice'
import { fetchAppealType } from '../../features/appealTypeSlice'
import { fetchInsuranceType } from '../../features/insuranceTypeSlice'
import { fetchFormTemplate } from "../../features/formTemplateSlice";


import { withRouter } from '../HOC/withRouter';
// import { appealType, Insurance, GroupName } from '../../hospitals';



const Dashboard = props => {
    const { Header, Content, Sider } = Layout;    
    const { getFieldDecorator } = props.form;

    const dispatch = useDispatch();
    const groupNameReducerState  = useSelector((state) => state.groupName.groupName);
    const appealTypeReducerState  = useSelector((state) => state.appelType.appealType);
    const insuranceReducerState  = useSelector((state) => state.insuranceType.insuranceType);

    // State   
    const [getGroupNameState, setGetGroupNameState] = useState([]);
    const [getInsuranceState, setGetInsuranceState] = useState([]);
    const [getAppealTypeState, setGetAppealTypeState] = useState([]);
    const [getSelectedGroupIDState, setGetSelectedGroupIDState] = useState('');

useEffect( () => {
    if (groupNameReducerState.length != 0) {
        console.log("GotGroupNAme in the component " , groupNameReducerState);
        setGetGroupNameState(groupNameReducerState)
    }

} ,[groupNameReducerState] )

useEffect( () => {
    if (insuranceReducerState.length != 0) {
        console.log("GOT INSURANCETYPE in the component " , insuranceReducerState);
        setGetInsuranceState(insuranceReducerState)
    }

} ,[insuranceReducerState] )

useEffect( () => {
    if (appealTypeReducerState.length != 0) {
        console.log("GotGroupNAme in the component " , appealTypeReducerState);
        setGetAppealTypeState(appealTypeReducerState)
    }

} ,[ appealTypeReducerState] )

useEffect( () => {
  if (groupNameReducerState.length == 0) {
    dispatch(fetchGroupName());
  }
  if (insuranceReducerState.length == 0) {
    dispatch(fetchInsuranceType());
  }
  if (appealTypeReducerState.length == 0) {
    dispatch(fetchAppealType());
  }

} ,[dispatch] )




const getAppealTypeHandler = (value) => {
    const { setFieldsValue } = props.form
    let getAppealType = appealTypeReducerState.filter(e => {
      return e.insuranceID === value
    })
    console.log("getAppealType", getAppealType);

    setGetAppealTypeState(getAppealType);

    setFieldsValue({
      appealType: getAppealType[0].appealID,
  });

  }

  const showAppealTypeHandler = () => {
    if (getAppealTypeState) {
      let getAppealType = [...getAppealTypeState]
    return getAppealType.map(e => {
      return <Select.Option key={e.appealID} value={e.appealID} > {e.appealsName} </Select.Option>
    })
  }
  }

  const getInsuranceTypeHandler = (value) => {
    const { setFieldsValue } = props.form
    let getInsurance = insuranceReducerState.filter(e => {
      return e.groupID === value
    })
    console.log("getInsurance", getInsurance);

    setGetInsuranceState(getInsurance);

    setFieldsValue({
      insuranceType: getInsurance[0].insuranceID,
  });

  getAppealTypeHandler(getInsurance[0].insuranceID)

  }
  
  const showInsuranceTypeHandler = () => {
    if (getInsuranceState) {
      let Insurance = [...getInsuranceState]
      return Insurance.map((e, index) => {
        return (
          <Select.Option key={e.insuranceID} value={e.insuranceID} > {e.insuranceType} </Select.Option>
        )
        // forceUpdate()
      })
    }
  }
  const showGroupNameHandler = () => {
    if (getGroupNameState) {
    return getGroupNameState.map(e => {
      return <Select.Option key={e.ID} value={e.ID} > {e.name} </Select.Option>
    })
    }
  }

const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        dispatch(fetchFormTemplate(values.appealType))
        props.navigate('/userDetails', { state: { val: values, selectedGroupID : getSelectedGroupIDState } })
      }
    })
  }

    return <div>
    <div className="root">
      <Layout>
        <HeaderComponent />
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <div className="content center-start">
              {/* <div className="inner-title">
                Step 1
              </div> */}
              <Form layout="vertical" className="content-center" style={{ width: '100%', padding: '20px 15%' }} onSubmit={handleSubmit}>
                <div className="innerContent content-center">
                  <div className="inner-title">
                    Insurance Details
                  </div>
                  <div>

                    <Row style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Col span={8}>
                        <div>
                          <Form.Item
                            className="input"
                            label="Group Name"
                            >
                            {getFieldDecorator('groupName', {
                              rules: [{ required: true, message: "please select a Group Name!" }]
                          })(

                              <Select
                                  onChange={(value) => { 
                                    getInsuranceTypeHandler(value);
                                      setGetSelectedGroupIDState(value);
                                  }}
                                  showSearch
                                  style={{ width: '100%' }}
                                  placeholder="Select a Group Name"
                                  optionFilterProp="children"
                                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                                  {showGroupNameHandler()}
                              </Select>
                          )}
                          </Form.Item>
                        </div>
                      </Col>
                      
                      <Col span={8}>
                        <div>
                          <Form.Item
                            className="input"
                            label="Insurance"
                        >
                            {getFieldDecorator('insuranceType', {
                                rules: [{ required: true, message: "please select an Insurance type!" }]
                            })(
                                <Select
                                    onChange={(value) => { getAppealTypeHandler(value) }}
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select an Insurance"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {showInsuranceTypeHandler()}
                                </Select>
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Col span={8} >
                        <div>
                          <Form.Item
                            className="input"
                            label="Appeal Type"
                          >
                            {getFieldDecorator('appealType', {
                              // defaultValue: state.getAppealType.length  != 0 ? state.getAppealType[0] : '' ,
                              rules: [{ required: true, message: "please select an Appeal type!" }]
                            })(

                              <Select
                                onChange={ () => {}}
                                showSearch
                                // defaultActiveFirstOption={true}
                                // value= {state.getAppealType.length  != 0 ? state.getAppealType[0].id : '' }
                                style={{ width: '100%' }}
                                placeholder="Select an Appeal"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                                {showAppealTypeHandler()}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>



                  </div>
                </div>
                <div className="innerContent " style={{ borderTop: '0px', padding: "0px" }}>
                  <Row className="footer">
                    <Col span={8}>
                      <div style={{ textAlign: 'left' }}>
                        {/* <Button type="primary" className='backBtn' onClick={() => {}}
                        >
                          BACK
                        </Button> */}
                      </div>

                    </Col>
                    <Col span={8}>
                      {/* <div className="completeDiv">
                        0% Complete
                      </div> */}
                    </Col>
                    <Col span={8}>
                      <div style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit" className='nextBtn' style={{backgroundColor: '#e84c89'}}  >
                          NEXT
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  </div>
}

export default withRouter(Form.create()(Dashboard));
// export default withRouter(Form.create()(Dashboard));


