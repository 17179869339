import './App.css';
import { connect } from 'react-redux';

import {Routes, Route} from 'react-router-dom'
import Dashboard from './Component/Dashboard';
import UserDetailsComponent from './Component/userDetails/index';
// import PreviewComponent from './Component/template/initialTemplate/exportPdf';
// import PreviewComponent from './Component/Preview/exportPdf';

function App() {
  return (
    <div className="App">
      <Routes forceRefresh={true}>
        <Route path='/' element={<Dashboard />} />
        <Route path='userDetails' element={<UserDetailsComponent />} />
        {/* <Route path='Preview' element={<PreviewComponent />} /> */}
      </Routes>
    </div>
  );
}

const mapStateToProps = state =>{
  return{

  }
}


export default connect(mapStateToProps, null)(App);
