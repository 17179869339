import React, { useEffect, useState } from "react";
// import { PDFdiver, Page, div, div, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Button, Row, Form, Select, Col, Input, Divider } from "antd";
import ATVIVO from '../../../assets/atvivo.png'


const styles = {
    page: {
        backgroundColor: "#ffffff",
        width: "100%",
        // border: '1px solid #595959',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 50,
        fontFamily: "timesNewRomanBold",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },

    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    section: {
        marginTop: 10,

        // display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",

        flexShrink: 1,
    },
    splitSection: {
        marginTop: 10,

        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",

        flexShrink: 1,
    },
    logo_Container: {
        flexGrow: 1,
        padding: 10,
    },

    image: {
        width: "80px",
    },

    headerContainer: {
        flexGrow: 1,
        padding: 10,
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        fontSize: 17,
    },
    headerPrimaryText: {
        // fontWeight: "bold",
        fontSize: 16,
    },
    scondary_Header_div: {
        fontSize: 12,
    },

    divider: {
        width: "100%",
        marginHorizontal: 3,
        marginVertical: 10,
        borderBottom: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "#595959",
        opacity: 0.6,
    },
    primaryPredivDetialsdiv: {
        fontSize: 12,
        fontFamily: "timesNewRoman",
        // fontWeight: "bold",
        color: "#000000a6",
        marginBottom: 6,
    },

    detailsPrimaryText: {
        fontFamily: "timesNewRoman",
        // fontWeight: "bold",
        fontSize: 14,
        // fontWeight: "bold",
        marginBottom: 3,
        // color: '#000000a6'
        color: "#000",
    },
    detailsScondaryText: {
        fontFamily: "timesNewRoman",
        fontSize: 14,
        color: "#000",
    },
    contentText: {
        fontFamily: "timesNewRoman",
        // fontWeight: 'bold',
        fontSize: 14,
        marginBottom: 3,
        // color: '#000000a6'
        color: "#000",
    },
    contentPrimaryText: {
        fontFamily: "timesNewRoman",
        // fontWeight: 'bold',
        fontSize: 13,
        marginBottom: 3,
        color: '#000'
    },

    contentSection: {
        marginVertical: 10,
    },
    margin_top_10: {
        marginTop: 10
    },
    margin_top_15: {
        marginTop: 15
    },
    margin_top_20: {
        marginTop: 20
    },
    margin_top_5: {
        marginTop: 5
    },
    margin_bottom: {
        marginBottom: 10
    },
    logo_Container: {

        flexGrow: 1,
        padding: 10,
        marginBottom: 10

    },


    Container_left: {
        width: "50%",
        padding: 10,
    },
    Container_right: {
        width: "50%",
        padding: 10,
        flexDirection: "column",
        wordWrap: "break-word",
        alignItems: "flex-end",
        textAlign: "right",
    },
    FooterSection: {
        display: "flex",
        flexDirection: "column",
        marginTop: 30,
    },
};

// Create Document Component
const CMS_and_MedicaidPreview = (props) => {
    const {
        getAppealType,
        getInsurance,
        getGroupName,
        userDetails,
        currentDate,
        getFormTemplate
    } = props;

    const [getUserDetails, setGetUserDetails] = useState({});

    return (
        <div style={{ padding: "0px 25px" }}>
            <div style={styles.page}>
                <div style={styles.container}>
                    <div style={styles.section} fixed>
                        <div style={styles.headerContainer}>
                            <div style={styles.logo_Container}>
                                <img style={styles.image} src={ATVIVO} />
                            </div>

                            <div style={styles.headerPrimaryText}>
                                {getGroupName.addressline1 ? getGroupName.addressline1 : ''}
                            </div>
                            <div style={styles.headerPrimaryText}>
                                {getGroupName.addressline2 ? getGroupName.addressline2 : ''}
                            </div>
                            {getGroupName.phone &&
                                <div style={styles.headerPrimaryText}>
                                    {getGroupName.phone}
                                </div>}
                        </div>


                        <div style={styles.divider} fixed> </div>

                        <div style={styles.splitSection} >
                            <div style={styles.Container_left}>
                                <div >
                                    <div style={styles.detailsPrimaryText} >
                                        DATE : {currentDate}
                                    </div>
                                </div>
                            </div>

                            <div style={styles.Container_right}>
                                <div style={styles.detailsPrimaryText}>
                                    Attention : {getFormTemplate ? getFormTemplate.attention : ''}
                                </div>

                            </div>

                        </div>


                        {/* PERSONAL DETAILS */}
                        <div style={styles.Container_left}>
                            <div  >
                                <div style={styles.detailsPrimaryText} >
                                    Patient name :
                                    {userDetails.patientName ? ` ${userDetails.patientName}` : ""}
                                </div>
                                {/* <Text style={styles.primaryPreviewDetialsText} >
                                    {getUserDetails ? getUserDetails.firstName : ''} {getUserDetails ? getUserDetails.lastName : ''}
                                </Text> */}
                            </div>
                            <div style={styles.margin_top_5}>
                                <div
                                    style={styles.detailsPrimaryText}>
                                    Patient DOB :
                                    {userDetails.patientDOB ? ` ${userDetails.patientDOB}` : ""}
                                </div>
                            </div>
                            <div style={styles.margin_top_5}>
                                <div
                                    style={styles.detailsPrimaryText}>
                                    Member ID : {userDetails.memberID ? ` ${userDetails.memberID}` : ""}
                                </div>
                            </div>
                            <div style={styles.margin_top_5}>
                                <div
                                    style={styles.detailsPrimaryText}>
                                    Claim ID :
                                    {userDetails.claimNumber ? ` ${userDetails.claimNumber}` : ""}
                                </div>
                            </div>
                            <div style={styles.margin_top_5}>
                                <div
                                    style={styles.detailsPrimaryText}>
                                    Date Of Service :
                                    {userDetails.DOS ? ` ${userDetails.DOS}` : ""}
                                </div>
                            </div>
                        </div>
                        {/* </View> */}


                        {/* <View style={styles.section} > */}
                        <div style={styles.contentSection} >
                            {getFormTemplate.contentOne &&
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentOne}
                                </div>
                            }

                        </div>
                        {getFormTemplate.contentTwo &&
                            <div style={styles.margin_top_5}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentTwo}
                                </div>
                            </div>
                        }

                        {getFormTemplate.contentThree &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentThree}
                                </div>
                            </div>
                        }

                        {getFormTemplate.contentFour &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentFour}
                                </div>
                            </div>
                        }

                        {getFormTemplate.contentFive &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentFive}
                                </div>
                            </div>
                        }


                        {getFormTemplate.contentSix &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentSix}
                                </div>
                            </div>
                        }
                        {getFormTemplate.contentSeven &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentSeven}
                                </div>
                            </div>
                        }
                        {getFormTemplate.contentEight &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentEight}
                                </div>
                            </div>
                        }
                        {getFormTemplate.contentNine &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentNine}
                                </div>
                            </div>
                        }
                        {getFormTemplate.contentTen &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentTen}
                                </div>
                            </div>
                        }
                        {getFormTemplate.contentEleven &&
                            <div style={styles.margin_top_15}>
                                <div style={styles.contentPrimaryText}>
                                    {getFormTemplate.contentEleven}
                                </div>
                            </div>
                        }

                        <div style={styles.margin_top_15}>
                            <div style={styles.contentSection}>
                                <div style={styles.detailsPrimaryText}>
                                    Thank You
                                </div>

                                <div style={styles.detailsPrimaryText}>
                                    {getFormTemplate.billingDept}
                                </div>

                                <div style={styles.detailsPrimaryText}>
                                    Billing Department
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default CMS_and_MedicaidPreview;