import React, { useEffect, useState } from 'react';
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Button, Row, Form, Select, Col, Input, Divider } from 'antd';


import times_new_roman_bold from '../../../assets/fonts/TimesNewRoman/timesNewRomanBold.ttf'
import times_new_roman_bold_italic from '../../../assets/fonts/TimesNewRoman/timesNewRomanBoldItalic.ttf'
import times_new_roman_italic from '../../../assets/fonts/TimesNewRoman/timesNewRomanItalic.ttf'
import times_new_roman from '../../../assets/fonts/TimesNewRoman/timesNewRoman.ttf'

import ATVIVO from '../../../assets/atvivo.png'

Font.register({ family: 'timesNewRomanBold', format: "truetype", src: times_new_roman_bold });
Font.register({ family: 'timesNewRomanBoldItalic', format: "truetype", src: times_new_roman_bold_italic });
Font.register({ family: 'timesNewRomanItalic', format: "truetype", src: times_new_roman_italic });
Font.register({ family: 'timesNewRoman', format: "truetype", src: times_new_roman });

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff',
        width: '100%',
        border: '1px solid #595959',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 50,
        fontFamily: "timesNewRoman"
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    container: {

        display: 'flex',
        flex: 1,
    },
    section: {
        marginTop: 10,

        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        flexShrink: 0

    },
    contentSection: {
        marginVertical: 10,
    },
    margin_top_10: {
        marginTop: 10
    },
    margin_top_15: {
        marginTop: 15
    },
    margin_top_5: {
        marginTop: 5
    },
    margin_bottom: {
        marginBottom: 10
    },
    logo_Container: {

        flexGrow: 1,
        padding: 10,
        marginBottom: 10

    },

    image: {
        width: "80px",
    },

    headerContainer: {
        flexGrow: 1,
        // padding: 10,
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 17,
        marginTop: 10,
        display: 'flex',
        // flexWrap: 'nowrap',
        // flexDirection: 'row',
        // flexShrink: 0
    },
    headerPrimaryText: {

        // fontWeight: 'bold',
        fontSize: 12,
        // lineHeight: 1.5
    },
    divider: {
        width: '100%',
        marginHorizontal: 3,
        marginVertical: 10,
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#595959',
        opacity: 0.6
    },

    detailsPrimaryText_bold: {
        fontFamily: "timesNewRomanBold",
        fontWeight: 'bold',
        fontSize: 11,
        marginBottom: 3,
        color: '#000'
    },
    detailsPrimaryText: {
        fontFamily: "timesNewRoman",
        fontSize: 11,
        marginBottom: 3,
        color: '#000'
    },
    detailsScondaryText: {
        fontFamily: "timesNewRoman",
        fontSize: 11,
        color: "#000",
    },
    contentPrimaryText: {
        fontFamily: "timesNewRoman",
        fontWeight: 'bold',
        fontSize: 12,
        marginBottom: 3,
        color: '#000'
    },

    Container_left: {
        width: '50%',
        padding: 10,
    },
    Container_right: {
        width: '50%',
        padding: 10,
        flexDirection: 'column',
        wordWrap: 'break-word',
        alignItems: 'flex-end',
        textAlign: 'right'
    }

});

// Create Document Component
const ClaimsReconsiderationPdfTemplate = (props) => {

    const {
        getAppealType,
        getInsurance,
        getGroupName,
        userDetails,
        currentDate,
        getFormTemplate
    } = props;
    const [getUserDetails, setGetUserDetails] = useState({})

    useEffect(() => {
        if (userDetails.length != 0) {
            setGetUserDetails(userDetails)
        }

        // return () => {
        //   second
        // }
    }, [userDetails])


    const imageUrl = 'https://gleneaglesglobalhealthcitychennai.com/wp-content/uploads/2020/02/GGH_CHENNAI_logo.png'
    return (


        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View style={styles.section} fixed>

                        <View style={styles.headerContainer}>
                            <View style={styles.logo_Container}>
                                <Image style={styles.image} src={ATVIVO} />
                            </View>

                            <Text style={styles.headerPrimaryText}>
                                {getGroupName.addressline1 ? getGroupName.addressline1 : ''}
                            </Text>
                            <Text style={styles.headerPrimaryText}>
                                {getGroupName.addressline2 ? getGroupName.addressline2 : ''}
                            </Text>
                            {getGroupName.phone &&
                                <Text style={styles.headerPrimaryText}>
                                    {getGroupName.phone}
                                </Text>}
                        </View>
                    </View>


                    <View style={styles.divider} fixed> </View>

                    <View style={styles.section} >
                        <View style={styles.Container_left}>
                            <View >
                                <Text style={styles.detailsPrimaryText} >
                                    DATE : {currentDate}
                                </Text>
                            </View>
                        </View>

                        <View style={styles.Container_right}>
                            <Text style={styles.detailsPrimaryText}>
                                Attention : {getFormTemplate.attention}
                            </Text>

                        </View>

                    </View>

                    {/* PERSONAL DETAILS */}
                    {/* <View style={styles.section} > */}
                    <View style={styles.Container_left}>
                        <View  >
                            <Text style={styles.detailsPrimaryText} >
                                Patient name :
                                {userDetails.patientName ? ` ${userDetails.patientName}` : ""}
                            </Text>
                            {/* <Text style={styles.primaryPreviewDetialsText} >
                                    {getUserDetails ? getUserDetails.firstName : ''} {getUserDetails ? getUserDetails.lastName : ''}
                                </Text> */}
                        </View>
                        <View style={styles.margin_top_5}>
                            <Text
                                style={styles.detailsPrimaryText}>
                                Patient DOB :
                                {userDetails.patientDOB ? ` ${userDetails.patientDOB}` : ""}
                            </Text>
                        </View>
                        <View style={styles.margin_top_5}>
                            <Text
                                style={styles.detailsPrimaryText}>
                                Member ID : {userDetails.memberID ? ` ${userDetails.memberID}` : ""}
                            </Text>
                        </View>
                        <View style={styles.margin_top_5}>
                            <Text
                                style={styles.detailsPrimaryText}>
                                Claim ID :
                                {userDetails.claimNumber ? ` ${userDetails.claimNumber}` : ""}
                            </Text>
                        </View>
                        <View style={styles.margin_top_5}>
                            <Text
                                style={styles.detailsPrimaryText}>
                                 Date Of Service :
                                    {userDetails.DOS ? ` ${userDetails.DOS}` : ""}
                            </Text>
                        </View>
                    </View>
                    {/* </View> */}


                    {/* <View style={styles.section} > */}
                    <View style={styles.contentSection} >
                        {getFormTemplate.contentOne &&
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentOne}
                            </Text>
                        }
                    </View>

                    {getFormTemplate.contentTwo &&
                        <View style={styles.margin_top_5}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentTwo}
                            </Text>
                        </View>
                    }

                    {getFormTemplate.contentThree &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentThree}
                            </Text>
                        </View>
                    }

                    {getFormTemplate.contentFour &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentFour}
                            </Text>
                        </View>
                    }

                    {getFormTemplate.contentFive &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentFive}
                            </Text>
                        </View>
                    }
                    {getFormTemplate.contentSix &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentSix}
                            </Text>
                        </View>
                    }
                    {getFormTemplate.contentSeven &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentSeven}
                            </Text>
                        </View>
                    }
                    {getFormTemplate.contentEight &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentEight}
                            </Text>
                        </View>
                    }
                    {getFormTemplate.contentNine &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentNine}
                            </Text>
                        </View>
                    }
                    {getFormTemplate.contentTen &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentTen}
                            </Text>
                        </View>
                    }
                    {getFormTemplate.contentEleven &&
                        <View style={styles.margin_top_15}>
                            <Text style={styles.contentPrimaryText}>
                                {getFormTemplate.contentEleven}
                            </Text>
                        </View>
                    }




                    <View style={[styles.contentSection, styles.margin_top_15]}>
                        <Text style={styles.detailsPrimaryText}>
                            Thank You
                        </Text>

                        <Text style={styles.detailsPrimaryText}>
                            {getFormTemplate.billingDept}
                        </Text>

                        <Text style={styles.detailsPrimaryText}>
                            Billing Department
                        </Text>
                    </View>

                </View>

                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    totalPages > 1 ? `${pageNumber} / ${totalPages}` : ''
                )} fixed />

            </Page>
        </Document>



    );
}

export default ClaimsReconsiderationPdfTemplate;