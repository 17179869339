import React from "react";
import { Layout, Menu, Row, Col, Input, Avatar, Badge, Dropdown } from "antd";
// import versioLogo from "../../../assets/VersioLogo_Full.png";
import './styles.css';

import LOGO from '../../assets/Innovative.png'

// import { history } from "../../../container/history";
import { DashboardOutlined, LogoutOutlined, DownOutlined, ToolOutlined, UserOutlined, ClockCircleOutlined, CarOutlined, TeamOutlined } from '@ant-design/icons';
const HeaderComponent = () => {

//   const menu = (
//     <Menu>
//       <Menu.Divider />
//       <Menu.Item key="3" onClick={()=>{  var user = UserPool.getCurrentUser();if(user){ console.log(user);user.signOut();history.push('/onboarding/login')}}}><LogoutOutlined /> Logout</Menu.Item>
//     </Menu>
//   );

    const { Header} = Layout;
   
    // const renderIcon = (iconStyle, iconName) => {
    //     return <i className={`${iconStyle} ${iconName}`}></i>;
    //   };
    return(
        <Header className="header" style={{borderBottom:'1px solid #dedede'}}>
            <Row style={{display: 'flex', height: 'auto', alignItems: 'center' }}>
              <Col span={12} style={{display: 'flex', height: '70px', alignItems: 'center', flexDirection: 'row' }}>
                <div className="inner-title" style={{display: 'flex', height: '70px', alignItems: 'center', flexDirection: 'row', margin: '0px' }}>
                  <img className="logo" src={LOGO} alt="Logo" />

                    Innovative Forms
                </div>
              </Col>
             
              <Col span={2} offset={10} className="Avatar-Icon">
                <Badge>
                  {/* <Avatar icon={renderIcon("fas", "fa-user")} /> */}
                </Badge>
                {/* <Dropdown trigger='click' overlay={menu}>
                <span className="icon-gray">
                {renderIcon("fas", "fa-chevron-down")}
                </span>
                </Dropdown> */}
    
                
              </Col>
            </Row>
          </Header>
    )
}

export default HeaderComponent;